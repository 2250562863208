import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Arrow from "../assets/image/arw.png";
import { Container, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { fetchKitDetails } from "../Redux/Actions";
import moment from "moment";
export const Profile = (props) => {
  const dispatch = useDispatch();
  const [kitDetails, setKitDetails] = useState();
  const [showDeviceId, setShowDeviceId] = useState(false);

  const SECRET_NUMBER = "45147"; // Replace with your actual secret number

  const handleShowDeviceId = async () => {
    let isPasswordVisible = false;
  
    // Create a container for input and show/hide toggle
    const wrapper = document.createElement("div");
  
    // Create input field
    const input = document.createElement("input");
    input.type = "password";
    input.placeholder = "Please Enter Your Admin ID";
    input.style.padding = "8px";
    input.style.marginRight = "10px";
    input.style.border = "1px solid #ccc";
    input.style.borderRadius = "5px";
    input.style.width = "80%";
  
    // Create show/hide button
    const toggleButton = document.createElement("button");
    toggleButton.style.cursor = "pointer";
    toggleButton.style.border = "none";
    toggleButton.style.background = "none";
    toggleButton.style.fontSize = "16px";
  
    // Eye icon SVG strings
    const eyeIcon = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>`;
  
    const eyeSlashIcon = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path></svg>`;
  
    // Set initial icon
    toggleButton.innerHTML = eyeIcon;
  
    toggleButton.onclick = () => {
      isPasswordVisible = !isPasswordVisible;
      input.type = isPasswordVisible ? "text" : "password";
      toggleButton.innerHTML = isPasswordVisible ? eyeSlashIcon : eyeIcon;
    };
  
    // Append input and button to wrapper
    wrapper.appendChild(input);
    wrapper.appendChild(toggleButton);

    // Show SweetAlert modal with custom content
    const secret = await swal({
      text: " ",
      content: wrapper,
      buttons: {
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    });


    if (secret && input.value === SECRET_NUMBER) {
      setShowDeviceId(true);
    } else if (secret !== null) {
      swal({
        title: "Error!",
        text: "Incorrect Admin Id",
        icon: "error",
        timer: 2000,
      });
    }
  };


  useEffect(async () => {
    try {
      let body = { kid: props.location.state.kitId, userId: props.location.state.userId }
      const response = await dispatch(
        fetchKitDetails(body)

      );

      const successResult = response?.payload;
      if (successResult) {
        if (successResult?.status == true) {
          setKitDetails(successResult?.data);
        } else {
          swal({
            title: "Error!",
            text: successResult?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  }, []);
  return (
    <>
      <div>
        <Link to="/dashboard" className="go-back">
          <img src={Arrow} alt="Go Back" /> Go Back to Dashboard
        </Link>
        <Container fluid className="for_wid">
          <Row className="kit_content_back">
            <Col lg={12} xl={6}>
              <div className="search-bar">
                <div class="kit_id">
                  {/* <h2>User Profile For Kit #{props.match.params.id}</h2> */}
                  <h2>User Profile</h2>
                  <p>
                    <strong>Username:</strong>{" "}
                    {kitDetails && kitDetails?.username}
                  </p>
                  <p>
                    <strong>Registration Date: </strong>
                    {moment(
                      new Date(
                        kitDetails && kitDetails?.Registration_date
                      ).toUTCString()
                    ).format("L")}
                  </p>
                  <p>
                    <strong>Deactivation Date:</strong>{" "}
                    {kitDetails && kitDetails?.deactivation_date != null
                      ? kitDetails && kitDetails?.deactivation_date != "--"
                        ? moment(
                          new Date(
                            kitDetails && kitDetails?.deactivation_date
                          ).toUTCString()
                        ).format("L")
                        : "--"
                      : "--"}
                  </p>
                  <p>
                    <strong>Date of Birth: </strong>

                    {kitDetails && kitDetails.dob !== null
                      ? kitDetails && kitDetails.dob != ""
                        ? moment(
                          new Date(
                            kitDetails &&
                            kitDetails.dob.split("/").reverse().join("/")
                          ).toUTCString()
                        ).format("L")
                        : "--"
                      : "--"}
                  </p>
                  <p>
                    <strong>City/State of Residence: </strong>{" "}
                    {kitDetails && kitDetails?.address}
                  </p>
                  <p>
                    <strong>Zip Code:</strong>{" "}
                    {kitDetails && kitDetails?.zipcode}
                  </p>
                  <p>
                    <strong>Gender:</strong> {kitDetails && kitDetails?.gender}
                  </p>
                  <p>
                    <strong>Ethnicity:</strong>{" "}
                    {kitDetails && kitDetails?.ethnicity}
                  </p>
                  <p>
                    <strong>Height(inches):</strong>{" "}
                    {kitDetails && kitDetails?.height}
                  </p>
                  <p>
                    <strong>Weight(lbs) : </strong>{" "}
                    {kitDetails && kitDetails?.weight}
                  </p>
                  <p>
                    <strong> Device Id : </strong>
                    {kitDetails && (
                      <>
                        {showDeviceId ? (
                          kitDetails.purpleAirId
                        ) : (
                          <button
                            onClick={handleShowDeviceId}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              padding: 0,
                            }}
                          >
                            ******* <FaEye />
                          </button>
                        )}
                      </>
                    )}
                  </p>
                  {/* <a href="#">Send Reset Password Link</a> */}
                </div>
              </div>
            </Col>
            {/* <Col lg={12} xl={6} style={{"border-left":"1px solid grey"}}>
              <div className="pro-table" >
                <p className="text-center"> <b>Devices</b></p>
                <p><b>PAF ID :</b> {kitDetails?.pafId}</p>
                <p><b>PURPLE AIR ID :</b>{kitDetails?.purpleAirId}</p>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};
