import React, { useEffect, useState } from "react";
import { useHistory,Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { logoutAction } from "../Redux/Actions";
import moment from "moment-timezone";
import M_Logo from "../assets/image/M_logo.png";
import { Button } from "react-bootstrap";
import { getItemLocalStorage } from "../Utils/Util";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.authReducer);

  const logout = async () => {
    try {
      const response = await dispatch(logoutAction());
      // console.log("response", response);
      const logoutData = response?.payload;
      if (logoutData) {
        if (logoutData == "LOGOUT") {
          history.push("/");
        } else {
          swal({
            title: "error!",
            text: logoutData?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (error) {
      swal({
        title: "Error",
        text: error?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  return (
    <div>
      <div className="mainHeader">
      <Link to="/dashboard">  <img src={M_Logo} className="img-fluid headLogo" /></Link>
        <div className="logoutPanel">
          <span>
            Logged in as MOV_Admin at{" "}
            {moment.tz("America/Chicago").format("h:mm:ss")} CST
          </span>
          <span>Admin ID: {getItemLocalStorage("adminId")}</span>
          <Button className="logButton" onClick={logout}>
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Header;
