import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  Table,
  Modal,
  FormCheck,
  Spinner,
} from "react-bootstrap";
import CloseBtn from "../assets/image/close.png";
import {
  getAllUsersAction,
  kitDeactivateAction,
  editPermissionsAction,
  GetUserAllNotes,
  updateListNote,
  addUserNote,
} from "../Redux/Actions";

import swal from "sweetalert";
import { getItemLocalStorage } from "../Utils/Util";
import { useDispatch } from "react-redux";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";

export const Dashboard = () => {
  const styles = {
    // position: 'absolute',
    margin: "auto",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
  };

  let history = useHistory();

  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(true);
  const [userId, setUserId] = useState(0);
  const [userListNotes, setuserListNotest] = useState();
  const _handleChange = () => {
    setIsChecked(!isChecked);
  };

  const [isChecked1, setIsChecked1] = useState(true);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [textAreaInput, setTextAreaInput] = useState("");
  const textAreaChage = (e) => {
    setTextAreaInput(e.target.value);
  };
  const handleCloseAddnote = () => {
    setAddNoteOpen(false);
    setAddNoteWarning(false);
    setTextAreaInput("");
  };
  const _handleChange1 = () => {
    setIsChecked1(!isChecked1);
  };
  const [boxCheck, setBoxCheck] = useState(false);
  const checkChangeForm = (e) => {
    setBoxCheck(e.target.checked);
  };
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});
  const handleClose = () => {
    setShow(false);
    setUser({});
  };
  const handleShow = (user) => {
    setShow(true);
    setUser(user);
  };
  const addNotesOpen = () => {
    setAddNoteOpen(true);
    setAddNoteWarning(false);
    setTextAreaInput("");
  };
  const updateNotes = async (e, data) => {
    let dataresult = await dispatch(updateListNote(data));
    let response = await dispatch(GetUserAllNotes({ userId: userId }));
    setuserListNotest(response?.payload?.data);
  };
  const handleKitDeactivate = async () => {
    try {
      const response = await dispatch(
        kitDeactivateAction({ kitNumber: user?.kitNumber })
      );
      const successResult = response?.payload;
      if (successResult) {
        if (successResult?.status == 200) {
          swal({
            title: "Success!",
            text: successResult?.data?.message,
            buttons: false,
            icon: "success",
            timer: 2000,
          });
          if (pageNum == 1) {
            getAllUsers();
          } else {
            setPageNum(1);
          }
        } else {
          swal({
            title: "Error!",
            text: successResult?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
    setShow(false);
    setUser({});
  };
  const handleAddNote = () => { };

  const [showNote, setShownote] = useState(false);
  const [addNoteWarning, setAddNoteWarning] = useState(false);
  const handleCloseNote = () => setShownote(false);
  const handleShowNote = async (e, data) => {
    let response = await dispatch(GetUserAllNotes({ userId: data.userId }));
    setuserListNotest(response?.payload?.data);
    setUserId(data.userId);
    setShownote(true);
  };

  const [showPermission, setShowpermission] = useState(false);
  const handleClosePermission = () => {
    setShowpermission(false);
    setUser({});
  };
  const handleShowPermission = (user) => {
    setShowpermission(true);
    setUser(user);
    var permissions = JSON.parse(user?.permissions);
    setIsChecked(permissions?.accountAccess == 1);
    setIsChecked1(permissions?.dataDownalod == 1);
  };
  const handleUserPermission = async () => {
    try {
      var permissions = {
        accountAccess: isChecked ? 1 : 0,
        dataDownalod: isChecked1 ? 1 : 0,
      };
      var userObj = {
        userId: user.id,
        permissions: JSON.stringify(permissions),
      };

      const response = await dispatch(editPermissionsAction(userObj));
      const successResult = response?.payload;
      if (successResult) {
        if (successResult?.status == 200) {
          swal({
            title: "Success!",
            text: successResult?.data?.message,
            buttons: false,
            icon: "success",
            timer: 2000,
          });
          if (pageNum == 1) {
            getAllUsers();
          } else {
            setPageNum(1);
          }
        } else {
          swal({
            title: "Error!",
            text: successResult?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
    setShowpermission(false);
    setUser({});
    setIsChecked(true);
    setIsChecked1(true);
  };

  const [showAdd, setShowadd] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleCloseAdd = () => setShowadd(false);
  const handleShowAdd = () => setShowadd(true);

  const searchResult = () => {
    if (pageNum == 1) {
      getAllUsers();
    } else {
      setPageNum(1);
    }
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const refreshData = () => {
    // getAllUsers();
    window.location.reload();
  };

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        getAllUsersAction({ page: pageNum, search: searchValue })
      );
      const successResult = response?.payload;
      if (successResult) {
        if (successResult?.status == 200) {
          // console.log("All user Data", successResult?.data);
          let all = [];
          if (pageNum == 1) {
            all = new Set([...successResult?.data?.data]);
          } else {
            all = new Set([...allUsers, ...successResult?.data?.data]);
          }
          setTotalRecords(successResult?.data?.totalRecord);
          setTOTAL_PAGES(
            Math.ceil(
              successResult?.data?.totalRecord / successResult?.data?.perPage
            )
          );
          setAllUsers([...all]);
          setLoading(false);
        } else {
          swal({
            title: "Error!",
            text: successResult?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [TOTAL_PAGES, setTOTAL_PAGES] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lastElement, setLastElement] = useState(null);

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          setPageNum((no) => no + 1);
        }
      },
      {
        root: document.querySelector("#table-start"), // window by default
        rootMargin: "0px",
        threshold: 0.25,
      }
    )
  );

  useEffect(() => {
    if (pageNum <= TOTAL_PAGES) {
      getAllUsers();
    }
  }, [pageNum]);
  const handleAddNoteSave = async () => {
    if (textAreaInput.length === 0) {
      setAddNoteWarning(true);
    } else {
      setAddNoteWarning(false);
      try {
        const response = await dispatch(
          addUserNote({ noteText: textAreaInput, userId: userId })
        );

        const sendCode = response?.payload;

        if (sendCode) {
          handleCloseAddnote();
          if (sendCode) {
            swal({
              title: sendCode?.status ? "Success" : "Error!",
              text: sendCode?.message,
              buttons: false,
              icon: sendCode?.status ? "success" : "error",
              timer: 2000,
            });
          } else {
            swal({
              title: "Error!",
              text: sendCode?.message,
              buttons: false,
              icon: "error",
              timer: 2000,
            });
          }
        }
      } catch (err) {
        swal({
          title: "Error",
          text: err?.response?.message,
          buttons: false,
          icon: "error",
          timer: 2000,
        });
      }
    }
  };
  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);
  const checkChange = (e, index) => {
    let newItems = userListNotes.slice();
    if (e.target.checked === true) {
      newItems[index].status = 1;
    } else {
      newItems[index].status = 0;
    }
    setuserListNotest(newItems);
  };

  const goUserProfile = (kitId, userId) => {
    history.push({
      pathname: '/profile',
      state: { kitId, userId }
    });
  }
  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="search-bar">
                <Form>
                  <Form.Group
                    controlId="formBasicEmail"
                    className="d-flex mob-block"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      className="serInput"
                      onChange={handleChange}
                    />
                    <Form.Select
                      aria-label="Default select example"
                      style={{ display: "none" }}
                    >
                      <option>All Fields</option>
                      <option value="1">10-15</option>
                      <option value="2">15-20</option>
                      <option value="3">20-25</option>
                    </Form.Select>
                    <Button
                      variant="primary"
                      type="button"
                      className="ser-btn"
                      onClick={searchResult}
                    >
                      Search
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      className="ser-btn"
                      onClick={() => {
                        refreshData();
                      }}
                    >
                      Refresh
                    </Button>
                    <Button
                      style={{ display: "none" }}
                      variant="primary"
                      type="button"
                      className="ser-btn"
                      onClick={handleShowAdd}
                    >
                      Add
                    </Button>
                  </Form.Group>
                </Form>
              </div>
              <div id="table-start" className="table-start">
                <p>{`Showing ${allUsers.length > 0 ? "1" : "0"}-${allUsers.length
                  } of ${totalRecords}`}</p>

                <Table responsive="sm">
                  <thead>
                    <tr className="thead-fixed">
                      {/* <th>Kit Serial Number</th> */}
                      <th>Email</th>
                      <th>Country</th>
                      <th>Status</th>
                      <th>Group ID</th>
                      <th>Registration Date</th>
                      <th>Last Login</th>
                      <th>Actions</th>
                      {/* <th>User Data</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {allUsers.length === 0 ? (
                      <tr>
                        <td className="text-message" colSpan="9">
                          <p> No record found !</p>{" "}
                        </td>
                      </tr>
                    ) : (
                      allUsers.map((data, i) => {
                        return i === allUsers.length - 1 &&
                          !loading &&
                          pageNum <= TOTAL_PAGES ? (
                          <tr key={`${data?.email}-${i}`} ref={setLastElement}>
                            {/* <td>

                              <a onClick={() => goUserProfile(data?.kitNumber, data?.userId)} style={{ "cursor": "pointer" }}>
                                {data?.kitNumber}{" "}
                              </a>
                            </td> */}
                            <td>{data?.email}</td>
                            <td>{data?.country}</td>
                            <td>
                              {data?.kitStatus === 0
                                ? "Deactivate"
                                : "Activate"}
                            </td>
                            <td>{data?.groupId}</td>

                            <td>
                              {data?.join
                                ? moment(data.join).format("DD-MM-YYYY")
                                : "-"}
                            </td>
                            <td>
                              {data?.lastLogin
                                ? moment(data.lastLogin).format("DD-MM-YYYY")
                                : "-"}
                            </td>
                            <td className="action-table">
                              {/* <a href="#">Edit</a> <span>|</span> */}
                              <a
                                className="click_view"
                                href="#"
                                onClick={() => goUserProfile(data?.kitNumber, data?.userId)} style={{ "cursor": "pointer" }}
                              >
                                View
                              </a>{" "}
                              <span>|</span>
                              <a
                                className="delete_kit"
                                href="#"
                                onClick={() => handleShow(data)}
                              >
                                Delete
                              </a>{" "}
                              <span>|</span>
                              <a
                                className="click_notes"
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  handleShowNote(e, data);
                                }}
                              >
                                Notes
                              </a>{" "}
                              <span>|</span>
                              <a
                                className="click_permission"
                                href="#"
                                onClick={() => handleShowPermission(data)}
                              >
                                Permissions
                              </a>{" "}
                              <span>|</span>
                              <a
                                className="click_view"
                                href="#"
                                onClick={() => goUserProfile(data?.kitNumber, data?.userId)} style={{ "cursor": "pointer" }}
                              >
                                View
                              </a>
                            </td>

                            <td>
                              <Button className="tablebutton">Data</Button>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            {/* <td>
                              <a onClick={() => goUserProfile(data?.kitNumber, data?.userId)} style={{ "cursor": "pointer" }}>
                                {data?.kitNumber}{" "}
                              </a>
                            </td> */}
                            <td>{data?.email}</td>
                            <td>{data?.country}</td>
                            <td>
                              {data?.kitStatus === 0
                                ? "Deactivate"
                                : "Activate"}
                            </td>
                            <td>{data?.groupId}</td>
                            <td>
                              {data?.join
                                ? moment(data.join).format("DD-MM-YYYY")
                                : "-"}
                            </td>
                            <td>
                              {data?.lastLogin
                                ? moment(data.lastLogin).format("DD-MM-YYYY")
                                : "-"}
                            </td>
                            <td className="action-table">
                              {/* <a href="#">Edit</a> <span>|</span> */}
                              <a
                                className="click_view"
                                href="#"
                                onClick={() => goUserProfile(data?.kitNumber, data?.userId)} style={{ "cursor": "pointer" }}
                              >
                                View
                              </a>{" "}
                              <span>|</span>
                              <a
                                className="delete_kit"
                                href="#"
                                onClick={() => handleShow(data)}
                              >
                                Delete
                              </a>{" "}
                              <span>|</span>
                              <a
                                className="click_notes"
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  handleShowNote(e, data);
                                }}
                              >
                                Notes
                              </a>{" "}
                              <span>|</span>
                              <a
                                className="click_permission"
                                href="#"
                                onClick={() => handleShowPermission(data)}
                              >
                                Permissions
                              </a>
                            </td>
                            {/* <td>
                              <Button className="tablebutton">Data</Button>
                            </td> */}
                          </tr>
                        );
                      })
                    )}

                    {loading && (
                      <ClipLoader
                        css={styles}
                        height={100}
                        width={20}
                        radius={100}
                        margin={30}
                        color={"#1a76d2"}
                        loading={loading}
                      />
                    )}
                  </tbody>
                </Table>

                <div></div>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          className="deactive-modal-dialog"
          centered
        >
          <Modal.Body className="p-0">
            <div className="deactivate-popup-content">
              <h4>Deactivate this kit?</h4>
              <p>
                NOTE: This cannot be undone. The account and its data will be
                saved but this kit will be deactivated.
              </p>
              <Button
                id="not_delete"
                onClick={handleClose}
                className="deactiveBtn"
              >
                No
              </Button>
              <Button onClick={handleKitDeactivate} className="deactiveBtn">
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showNote}
          size="lg"
          onHide={handleCloseNote}
          animation={false}
          className="note-modal-dialog"
          centered
        >
          <Modal.Body className="p-0">
            <div className="notes_popup">
              <button class="close" onClick={handleCloseNote}>
                X
              </button>
              <h4>Account Notes</h4>
              <div className="notes_list">
                {userListNotes && userListNotes.length !== 0
                  ? userListNotes.map((item, index) => {
                    return (
                      <div className="n1">
                        <form className="formcheckbox">
                          <Form.Group controlId="Resolved1">
                            <Form.Check
                              type="checkbox"
                              checked={item.status !== 0 ? true : false}
                              label=" Resolved"
                              onChange={(e) => {
                                checkChange(e, index);
                              }}
                            />
                          </Form.Group>
                        </form>
                        <p>{item.noteText}</p>
                        <div className="sva-btn">
                          <Button
                            className="n1_save_cta"
                            onClick={(e) => updateNotes(e, item)}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    );
                  })
                  : "No notes found."}
                {/* <div className="n1">
                                <span>January 19th, 2021 9:50:00 PM CST</span>
                                <form className="formcheckbox">
                                    <Form.Group controlId="Resolved1">
                                        <Form.Check type="checkbox" label=" Resolved" />
                                    </Form.Group>
                                </form>
                                <p>User complained their devices were not connecting to the BLE....after troubleshooting we decided to send them a replacement kit 5/1/2018</p>
                            </div>

                            <div className="n1">
                                <span>February 19th, 2021 9:50:00 PM CST</span>
                                <form className="formcheckbox">
                                    <Form.Group controlId="Resolved2">
                                        <Form.Check type="checkbox" label=" Resolved" />
                                    </Form.Group>
                                </form>
                                <p>User was upset about xyx xxxxxx</p>
                            </div>

                            <div className="n1">
                                <span>May 19th, 2021 9:50:00 PM CST</span>
                                <form className="formcheckbox">
                                    <Form.Group controlId="Resolved3">
                                        <Form.Check type="checkbox" label=" Resolved" />
                                    </Form.Group>
                                </form>
                                <p>User was tired xyz etc</p>
                                <Button className="n1_save_cta">Save</Button>
                            </div>

                            <div className="n1">
                                <span>February 19th, 2021 9:50:00 PM CST</span>
                                <form className="formcheckbox">
                                    <Form.Group controlId="Resolved4">
                                        <Form.Check type="checkbox" label=" Resolved" />
                                    </Form.Group>
                                </form>
                                <p>User was upset about xyx xxxxxx</p>
                            </div>

                            <div className="n1">
                                <span>May 19th, 2021 9:50:00 PM CST</span>
                                <form className="formcheckbox">
                                    <Form.Group controlId="Resolved5">
                                        <Form.Check type="checkbox" label=" Resolved" />
                                    </Form.Group>
                                </form>
                                <p>User was tired xyz etc</p>
                                <Button className="n1_save_cta">Save</Button>
                            </div> */}
              </div>
              <Button
                className="add_notes_cta"
                onClick={() => {
                  handleCloseNote();
                  addNotesOpen();
                }}
              >
                Add notes
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showPermission}
          size="md"
          onHide={handleClosePermission}
          animation={false}
          className="permission-modal-dialog"
          centered
        >
          <Modal.Body className="p-0">
            <Row className="justify-content-center">
              <Col sm={10}>
                <div className="give_permission">
                  <h4>Account Permissions</h4>
                  <div class="toggles-pop">
                    <span>Account Access</span>
                    <Form>
                      <div className="switch-container">
                        <label>
                          <input
                            checked={isChecked}
                            onChange={_handleChange}
                            className="switch"
                            type="checkbox"
                          />
                          <div>
                            <div></div>
                          </div>
                        </label>
                      </div>
                    </Form>
                    <hr />
                    <span>Data Download</span>
                    <Form>
                      <div className="switch-container">
                        <label>
                          <input
                            checked={isChecked1}
                            onChange={_handleChange1}
                            className="switch"
                            type="checkbox"
                          />
                          <div>
                            <div></div>
                          </div>
                        </label>
                      </div>
                    </Form>
                  </div>
                  <p>
                    NOTE: Only make these changes if the user's account has been
                    compromised or if payment has been stopped.
                  </p>
                  <Button
                    className="button"
                    id="cancel-permission_pop"
                    onClick={handleClosePermission}
                  >
                    Cancel
                  </Button>
                  <Button className="button" onClick={handleUserPermission}>
                    Save Changes
                  </Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={addNoteOpen}
          size="md"
          onHide={handleCloseAddnote}
          animation={false}
          className="permission-modal-dialog"
          centered
        >
          <Modal.Body className="p-0">
            <Row className="justify-content-center">
              <Col sm={40} className="addnote-width">
                <div className="give_permission give_permission">
                  <h4>Add user note</h4>
                  <form className="formcheckbox">
                    <div className="n1">
                      {/* <span>January 19th, 2021 9:50:00 PM CST</span> */}

                      {/* <Form.Group controlId="Resolved1">
                        <Form.Check
                          type="checkbox"
                          checked={boxCheck}
                          onChange={(e) => {
                            checkChangeForm(e);
                          }}
                          label=" Resolved"
                        />
                      </Form.Group> */}
                      <textarea
                        className={`${addNoteWarning === true ? "dangerBorder" : ""
                          }`}
                        required
                        value={textAreaInput}
                        onChange={(e) => {
                          textAreaChage(e);
                        }}
                      ></textarea>
                    </div>
                    <Button
                      className="button"
                      id="cancel-permission_pop"
                      onClick={handleCloseAddnote}
                    >
                      Cancel
                    </Button>
                    <Button className="button" onClick={handleAddNoteSave}>
                      Save
                    </Button>
                  </form>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={showAdd}
          size="lg"
          onHide={handleCloseAdd}
          animation={false}
          className="add-modal-dialog"
          centered
        >
          <Modal.Body className="p-0">
            <Row className="justify-content-center">
              <Col sm={9}>
                <div class="add-kit-form">
                  <img
                    src={CloseBtn}
                    className="close-cross"
                    id="close-kit"
                    onClick={handleCloseAdd}
                  />
                  <h4>Add New Kit Number</h4>
                  <Form>
                    <Row>
                      <Col md={6} sm={12}>
                        <Form.Label>Kit Serial Number</Form.Label>
                        <Form.Control placeholder="Kit Serial Number" />
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control placeholder="Email ID" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <Form.Label>Country</Form.Label>
                        <Form.Control placeholder="Country Name" />
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          className="me-sm-2 form-control"
                          id="inlineFormCustomSelect"
                        >
                          <option value="0">Active</option>
                          <option value="1">Inactive</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <Form.Label>Group ID</Form.Label>
                        <Form.Control placeholder="Enter Group ID" />
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Label>Registration Date</Form.Label>
                        <Form.Control type="date" name="date_of_birth" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <Form.Label>Last Login</Form.Label>
                        <Form.Control type="date" name="date_of_birth" />
                      </Col>
                      <Col md={6} sm={12}>
                        <Button className="button">Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
