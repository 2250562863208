import React,{ useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Button
} from "react-bootstrap";
import CloseBtn from "../assets/image/close.png";
import swal from "sweetalert";
import { getItemLocalStorage } from "../Utils/Util";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {getTermsOfUse,addTermsOfUse} from "../Redux/Actions";
import './style.css';

export const TermAndCondition = () => {
  const [addData, setAddData] = useState('')
  const dispatch = useDispatch();

  useEffect(async () => {
    const response =  await dispatch(getTermsOfUse())
    // console.log('=response',response);
    if(response?.type=='GET_TERMS')
      {
        setAddData(response?.payload)
      }
  }, []);

  const submitHander= async (e)=>{
    e.preventDefault()
    if(addData ===''){
        swal({
            title: "Error!",
            text: 'Terms of condtion required',
            buttons: true,
            icon: "error"
          });
    }
    let body = {content:addData}
    const res = await dispatch(addTermsOfUse(body))
    console.log("---------res",res);
    if(res?.payload?.status){
      swal({
        title: "Success!",
        text: res?.payload?.message,
        buttons: false,
        icon: "success",
        timer: 2000,
      });
    }else{
      swal({
        title: "Error!",
        text: res.err,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  }
  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <h2 className="text-center mt-5"> MOV Terms of Service </h2>
              <div className="search-bar">
                <Form onSubmit={(e)=>submitHander(e)}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <CKEditor
                        editor={ ClassicEditor }
                        data={addData}
                        onReady={ editor => {
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setAddData(data)
                        } }
                        onBlur={ ( event, editor ) => {
                         } }
                        onFocus={ ( event, editor ) => {
                        } }
                    />
                </Form.Group>
                <Button type="submit" className="cancel_cta">Save Changes</Button>
                </Form>
              </div>
        </Col>
    </Row>
    </Container>
    </div>
                      
    </>
  );
};
