  import React,{ useEffect, useState } from "react";
import {
  Container,
  Col,
  Row
} from "react-bootstrap";
import parse from 'html-react-parser';
import { useDispatch } from "react-redux";
import {getTermsOfUse} from "../Redux/Actions";
import './style.css';

export const TermsAndConditionData = () => {
  const [addData, setAddData] = useState('')
  const dispatch = useDispatch();

  useEffect(async () => {
    const response =  await dispatch(getTermsOfUse())
    // console.log('=response',response);
    if(response?.type=='GET_TERMS')
      {
        setAddData(response?.payload)
      }
  }, []);

 
  return (
    <>
      <div>
        <Container >
          <Row>
            <Col lg={12}>
              <h2 className="text-center mt-5">MOV Terms of Service </h2>
              <div className="search-bar">
                {parse(addData)}
              </div>
        </Col>
    </Row>
    </Container>
    </div>
                      
    </>
  );
};
